import Home from '@/components/home/Home'
import { EmployerProfilePage, WorkerProfilePage } from './Profile'
import { EmploymentContractsPage } from './EmploymentContracts'
import { AboutProjectPage } from './AboutProject'
import { ForEmployerPage } from './ForEmployer'
import { ForWorkerPage } from './ForWorker'
import { ReferenceInformationPage } from '@pages/ReferenceInformation'
import { FormsPage } from './Forms'
import { ChooseRolePage } from '@pages/ChooseRole/ChooseRolePage'
import { NotificationsPage } from '@pages/Notifications'
import { NotificationsMobileDetailsPage } from '@pages/Notifications/mobile'
import PersonalAffairsPage from '@pages/PersonalAffairs/PersonalAffairsPage'
import EmployeesPersonalFilesPage from '@pages/EmployeesPersonalFiles/EmployeesPersonalFilesPage'
import { LaborRecordsPage } from '@pages/LaborRecords/LaborRecordsPage'
import { CheckDocumentPage } from '@pages/CheckDocument'
import StaffingPage from '@pages/Staffing/StaffingPage'
import { GeneralInformationPage } from '@pages/ProfUnions'
import { TradeUnionsPage } from '@pages/TradeUnionsPage'
import { MembersTradeUnionPage } from '@pages/MembersTradeUnionPage'
import { AccidentsPage } from '@pages/Accidents/AccidentsPage'
import { GradingPage } from './Grading'
import { MainUnionsPage } from '@pages/MainUnionsPage'
import { CollectiveContractsPage } from '@pages/CollectiveContractsPage'
import { IndustryAgreement } from './IndustryAgreement'

export const routes = [
	{ Component: Home, exact: true, path: '/' },
	{ Component: ChooseRolePage, exact: true, path: '/role' },
	{ Component: WorkerProfilePage, exact: true, path: '/worker' },
	{ Component: EmployerProfilePage, exact: true, path: '/employer' },
	{ Component: EmploymentContractsPage, exact: true, path: '/contracts' },
	{ Component: StaffingPage, exact: true, path: '/staffing' },
	{ Component: PersonalAffairsPage, exact: true, path: '/personal-affairs' },
	{ Component: EmployeesPersonalFilesPage, exact: true, path: '/employees-personal-files' },
	{ Component: AboutProjectPage, exact: true, path: '/about-project' },
	{ Component: ForEmployerPage, exact: true, path: '/for-employers' },
	{ Component: ForWorkerPage, exact: true, path: '/for-workers' },
	{ Component: ReferenceInformationPage, exact: true, path: '/reference-information' },
	{ Component: FormsPage, exact: true, path: '/forms' },
	{ Component: ReferenceInformationPage, exact: true, path: '/reference-information/:key' },
	{ Component: LaborRecordsPage, exact: true, path: '/labor-records' },
	//уведомления
	{ Component: NotificationsPage, exact: true, path: '/notifications' },
	{ Component: NotificationsMobileDetailsPage, exact: true, path: '/notifications/:id' },
	{ Component: CheckDocumentPage, exact: true, path: '/check-document/:id/:type' },
	{ Component: GeneralInformationPage, exact: true, path: '/prof' },
	{ Component: TradeUnionsPage, exact: true, path: '/trade-unions' },
	{ Component: MainUnionsPage, exact: true, path: '/my-unions' },
	{ Component: MembersTradeUnionPage, exact: true, path: '/trade-members' },
	{ Component: CollectiveContractsPage, exact: true, path: '/col-contracts' },
	{ Component: AccidentsPage, exact: true, path: '/accidents' },
	{ Component: IndustryAgreement, exact: true, path: '/industry-agreement' },
	/*//TODO временно комментируется 12.09.2024 { Component: GradingPage, exact: true, path: '/grading' },*/
]
