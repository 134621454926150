import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import { useCallback, useEffect, useState } from 'react'
import { agreementsThunks, agreementsSelectors, agreementsActions } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { HrPagination } from '@/components/Pagination'
import { initialQueryState } from '@/entity/industryAgreement/model/reducer'
import { View } from '@features/eCollectiveContracts/registration/view'
import { Loader } from '@/components/Loader'
import { LoaderWrapper } from '../../ui/molecules/loaderWrapper/index'
import { CreateOrEdit } from '@features/industryAgreement/createOrEdit/CreateOrEdit'
import AgreementTable from '@features/industryAgreement/table/AgreementTable'

export const IndustryAgreement = () => {
	const dispatch = useDispatch()
	const [queryParams, setQueryParams] = useState(initialQueryState)
	const [isView, openView] = useState(false)
	const [isEdit, openEdit] = useState(false)
	const agreements = useSelector(agreementsSelectors.agreements)
	const agreement = useSelector(agreementsSelectors.agreement)

	useEffect(() => {
		dispatch(agreementsThunks.getDict())
	}, [])

	const fetchData = useCallback(
		(_queryParams = queryParams) => {
			const { page, pageSize } = _queryParams
			dispatch(agreementsThunks.getPage(page, pageSize))
		},
		[queryParams]
	)

	useEffect(() => fetchData(), [queryParams.page, queryParams.pageSize])

	const handleChangePage = (e, page) => setQueryParams({ ...queryParams, page: page - 1 })
	const handleChangeRowsPerPage = (size) => setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))

	const Close = () => {
		openView(false)
		dispatch(agreementsActions.resetAgreementXml())
		dispatch(agreementsActions.setAgreementSuccess(false))
	}

	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={'industryAgreement.title'}>
				<CreateOrEdit fetchData={fetchData}></CreateOrEdit>
				{agreements ? (
					<>
						<AgreementTable data={agreements} view={openView} edit={openEdit}></AgreementTable>
						<HrPagination
							id="hr-pagination"
							rowsPerPageitems={queryParams.rowsPerPage}
							rowsPerPage={queryParams.pageSize}
							numberOfElements={agreements.numberOfElements}
							totalElements={agreements.totalElements}
							totalPages={agreements.totalPages}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
							page={queryParams.page}
						/>
					</>
				) : (
					<LoaderWrapper>
						<Loader />
					</LoaderWrapper>
				)}
				{/*				{isView && !!contract ? (
					<View
						contract={contract}
						onClose={Close}
						isEmployee={isEmployee}
						fetchData={fetchData}
						isTradeUnion={isTradeUnion}
					></View>
				) : null}*/}
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}
