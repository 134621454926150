import { useTranslation } from 'react-i18next'
import { Button } from '@/ui'
import React from 'react'
import { useToggle } from '@/shared'
import { initialIndustryAgreement } from '@/entity/industryAgreement/model/reducer'
import { IndustryAgreement } from './form/IndustryAgreement'
import { useSelector, useDispatch } from 'react-redux'
import { handbookSelectors } from '@/entity/handbook'
import { agreementsThunks } from '@/entity'

export const CreateOrEdit = ({ fetchData }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isCreate, toggleCreate] = useToggle(false)

	const Close = () => {
		toggleCreate(false)
	}

	const onSubmit = (data) => {
		dispatch(agreementsThunks.getXml(data))
	}

	return (
		<div>
			<Button onClick={toggleCreate}>{t('industryAgreement.create')}</Button>
			{isCreate ? (
				<IndustryAgreement
					initialValues={initialIndustryAgreement}
					onCancel={Close}
					onSubmit={onSubmit}
					isEdit={false}
				></IndustryAgreement>
			) : null}
		</div>
	)
}
