import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styles from './agreementTable.module.scss'
import { headCells } from './agreementTable.headings'
import { getCurrentLanguage } from '@/i18next'
import { colContractsThunks } from '@/entity'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { Edit as EditIcon, View as ViewIcon } from '@app/images'

const AgreementTable = ({ data, view, edit }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()
	let actions = [
		{ icon: ViewIcon, title: t('actions.view'), action: 'view' },
		{ icon: EditIcon, title: t('actions.edit'), action: 'edit' },
	]
	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const getRequest = useCallback((externalId) => dispatch(colContractsThunks.getColContract(externalId)), [dispatch])

	const onActionMenuChoose = (mode, row) => {
		getRequest(row.externalId)
		switch (mode) {
			case 'view':
				view(true)
				break
			case 'edit':
				edit(true)
				break
			default:
				return false
		}
	}
	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index}>
				<td>{row.bin}</td>
				<td>{lang === 'rus' ? row.companyName : row.companyNameKaz || '-'}</td>
				<td>{lang === 'rus' ? row.unionName : row.unionNameKaz || '-'}</td>
				<td>{row.dateFrom}</td>
				<td>{row.dateTo || '-'}</td>
				<td>
					<DropDownMenu
						className={styles.minWidth}
						title={t('select_pls')}
						items={actions}
						onItemClick={(mode) => onActionMenuChoose(mode, row)}
					/>
				</td>
			</tr>
		),
		[lang, t]
	)
	const tableBody = useMemo(() => data?.map((row, index) => getTableRow(row, index)), [data, getTableRow])
	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{tableBody}</tbody>
			</table>
		</>
	)
}

export default AgreementTable
