import { ModalTemplate, Title } from '@/ui'
import styles from './style.module.scss'
import { useSelector } from 'react-redux'
import { handbookSelectors } from '@/entity/handbook'
import { LoadingBlocker } from '@/components/Loader'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { IndustryAgreementForm } from '@/entity/industryAgreement/ui/form/IndustryAgreementForm'

export const IndustryAgreement = ({ initialValues, onCancel, onSubmit, isEdit }) => {
	const { t } = useTranslation()
	const industryAgreement = useSelector(handbookSelectors.industryAgreement)
	const [filesMiddleWare, setFilesMiddleWare] = useState([])
	const { files } = initialValues

	const onSubmitMiddleWare = (data) => {
		let sendFileArray = []
		if (filesMiddleWare.length > 0) {
			sendFileArray = filesMiddleWare.map((file) => ({
				id: file.id,
			}))
		}
		// Включение массива файлов в объект данных
		let preparedData = { ...data, files: sendFileArray || [] }
		// Вызов onSubmit с подготовленными данными
		onSubmit(preparedData)
	}

	return (
		<ModalTemplate onClose={onCancel}>
			<div className={`scroll ${styles.wrapper}`}>
				{!!industryAgreement ? (
					<>
						<Title color={'black'}>
							{isEdit ? t('industryAgreement.titleEdit') : t('industryAgreement.titleCreate')}
						</Title>
						<Formik initialValues={initialValues} onSubmit={onSubmitMiddleWare}>
							<IndustryAgreementForm
								onCancel={onCancel}
								files={files}
								industryAgreement={industryAgreement}
							></IndustryAgreementForm>
						</Formik>
					</>
				) : (
					<LoadingBlocker localStyles={{ width: '65vw', height: '50vw' }} />
				)}
			</div>
		</ModalTemplate>
	)
}
