import React, { useEffect } from 'react'
import { useDigitalSign, useToggle } from '@/shared'
import { Button } from '@/ui'
import { Book } from '@app/images'
import { useTranslation } from 'react-i18next'
import { Form } from './form'
import { initialColContract } from '@/entity/collectiveContracts/model/reducer'
import { useSelector, useDispatch } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { colContractsActions, colContractsSelectors, colContractsThunks, userActions } from '@/entity'

export const RegistrationCollectiveContract = ({ fetchData, isEdit, openEdit }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isCreate, toggleCreate] = useToggle(false)
	const colContractXML = useSelector(colContractsSelectors.colXml)
	const isSuccess = useSelector(colContractsSelectors.isSuccess)
	const contract = useSelector(colContractsSelectors.colContract)
	const { successPayload, onSign, failedPayload, onClearPayload } = useDigitalSign()
	const options = useSelector(contractActionsSelectors.options)
	const onClear = () => {
		onClearPayload()
		dispatch(colContractsActions.setColXML(null))
		dispatch(userActions.setUserByBin(null))
		dispatch(colContractsActions.setColContractSuccess(false))
		dispatch(colContractsActions.resetColXml())
	}
	useEffect(() => {
		if (successPayload) {
			dispatch(colContractsThunks.sendSignedColContract(successPayload))
			onClearPayload()
		}
	}, [successPayload])
	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])
	useEffect(() => colContractXML && onSign(colContractXML), [colContractXML])
	const Close = () => {
		toggleCreate(false)
		openEdit(false)
	}
	useEffect(() => {
		if (isSuccess) {
			onClear()
			fetchData()
			Close()
		}
		return onClear()
	}, [isSuccess])
	let colContract =
		isEdit && contract.externalId
			? {
					...initialColContract,
					...contract,
					...contract.collectiveContractDetails,
					...contract.collectiveContractDetails.rateInfo,
					externalId: contract.externalId,
					enterpriseName: contract.companyName,
					enterpriseNameKaz: contract.companyNameKaz,
					overtimePayIs: !!contract.collectiveContractDetails.overtimePay,
					holidayPayIs: !!contract.collectiveContractDetails.holidayPay,
					nightTimePayIs: !!contract.collectiveContractDetails.nightTimePay,
					combinationPositionPayIs: !!contract.collectiveContractDetails.combinationPositionPay,
					termCompensationPayIs: !!contract.collectiveContractDetails.termCompensationPay,
					wayCompensationPayIs: !!contract.collectiveContractDetails.wayCompensationPay,
					wageIndexProcedureIs: !!contract.collectiveContractDetails.wageIndexProcedure,
			  }
			: {
					...initialColContract,
					externalId: null,
			  }
	const onSubmit = (data) => {
		let transformedColContract = {
			bin: data.bin,
			collectiveContractDetails: {
				eighthGradeMax: data.eighthGradeMax,
				eighthGradeMin: data.eighthGradeMin,
				fifthGradeMax: data.fifthGradeMax,
				fifthGradeMin: data.fifthGradeMin,
				firstGradeMax: data.firstGradeMax,
				firstGradeMin: data.firstGradeMin,
				fourthGradeMax: data.fourthGradeMax,
				fourthGradeMin: data.fourthGradeMin,
				fundingAmount: data.fundingAmount,
				rateInfo: {
					firstCategoryRate: data.firstCategoryRate,
					industryAgreementCoefficient: data.industryAgreementCoefficient,
					maxCoefficient: data.maxCoefficient,
					minCoefficient: data.minCoefficient,
				},
				relaxTime: data.relaxTime,
				secondGradeMax: data.secondGradeMax,
				secondGradeMin: data.secondGradeMin,
				securityFundingVolume: data.securityFundingVolume,
				seventhGradeMax: data.seventhGradeMax,
				seventhGradeMin: data.seventhGradeMin,
				sixthGradeMax: data.sixthGradeMax,
				sixthGradeMin: data.sixthGradeMin,
				thirdGradeMax: data.thirdGradeMax,
				thirdGradeMin: data.thirdGradeMin,
				workTime: data.workTime,
				dcolContractEnterpriseTypeCode: data.dcolContractEnterpriseTypeCode,
				dcolContractOwnershipTypeCode: data.dcolContractOwnershipTypeCode,
			},
			collectiveContractNumber: data.collectiveContractNumber,
			companyName: data.enterpriseName,
			companyNameKaz: data.enterpriseNameKaz,
			dateFrom: data.dateFrom,
			dateTo: data.dateTo,
			externalId: data.externalId,
			files: data.files,
			unions: data.unions,
			extraGuarantee: data.extraGuarantee,
		}
		// Добавляем overtimePay только если overtimePayIs равно true
		if (data.overtimePayIs) {
			transformedColContract.collectiveContractDetails.overtimePay = data.overtimePay
		}
		if (data.holidayPayIs) {
			transformedColContract.collectiveContractDetails.holidayPay = data.holidayPay
		}
		if (data.nightTimePayIs) {
			transformedColContract.collectiveContractDetails.nightTimePay = data.nightTimePay
		}
		if (data.combinationPositionPayIs) {
			transformedColContract.collectiveContractDetails.combinationPositionPay = data.combinationPositionPay
		}
		if (data.termCompensationPayIs) {
			transformedColContract.collectiveContractDetails.termCompensationPay = data.termCompensationPay
		}
		if (data.wayCompensationPayIs) {
			transformedColContract.collectiveContractDetails.wayCompensationPay = data.wayCompensationPay
		}
		if (data.wageIndexProcedureIs) {
			transformedColContract.collectiveContractDetails.wageIndexProcedure = data.wageIndexProcedure
		}
		dispatch(colContractsThunks.generateXML(transformedColContract))
	}

	return (
		<div>
			<Button icon={Book} iconSize={'big'} onClick={toggleCreate}>
				{t('contracts_tab.registration_collective_contract')}
			</Button>
			{isCreate || (isEdit && colContract.externalId) ? (
				<Form initialValues={colContract} onCancel={Close} options={options} onSubmit={onSubmit} isEdit={isEdit}></Form>
			) : null}
		</div>
	)
}
